<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-cog-outline" title="Configuration import">
    <v-card class="pa-4" v-if="clientAdmin">
      <item-list-loader
          namespace="organisations"
          :custom-filters="{isDistributor: true}"
          :limit="1000"
      >
        <template v-slot:main="orgSlot">
          <v-select
              :hide-details="true"
              v-model="selectedOrg"
              :items="orgSlot.items"
              item-text="name"
              item-value="id"
              label="Select organisation"
          ></v-select>
        </template>
      </item-list-loader>


    </v-card>

    <v-card v-if="selectedOrg" class="pa-4 mt-4">
      <item-loader namespace="organisations" :id="selectedOrg">
        <template v-slot:default="singleSlot">
          <h2 class="text-h6">Import hose configurations for {{ singleSlot.item.name }}</h2>
          <v-alert color="light" class="mb-4">
            <div class="d-flex justify-space-between">
              <div class="flex-fill">You can import hose configurations for this organisation by uploading a template CSV file.</div>
              <v-btn depressed class="mr-2" color="error"  :loading="exportLoading" @click="exportExisting(false)" x-small>Download existing</v-btn>
              <v-btn depressed color="info" :loading="exportLoading" @click="exportExisting(true)" x-small>Download blank template</v-btn>
              <global-error-inline :errors="exportErrors"/>
            </div>
          </v-alert>
          <v-file-input
              v-model="file"
              :label="file ? file.name : 'Select CSV file'"
              outlined
              accept=".csv"
              @change="readFile"
          ></v-file-input>

          <global-error-inline :errors="errors"/>

          <!-- read lines -->
          <div v-if="readLines.length > 0" class="mt-4">
            <item-list-loader
                namespace="hoseConfigurations"
                :limit="1000"
                :disable-no-results="true"
                :on-new-data="updateConfigs"
                :custom-filters="{organisationId: [selectedOrg]}">
              <template #main="configSlot">
                <v-simple-table class="mb-4" dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Validation</th>
                      <th v-for="header in headers" :key="header">{{ header }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(line,lineIndex) in readLines" :key="line.id">
                      <td>

                        <v-menu
                            :offset-y="true"
                            :close-on-content-click="true"
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-btn
                                color="light"
                                elevation="0"
                                class="px-0"
                                small
                                v-bind="attrs"
                                :color="alerts(line,configSlot.items).type"
                                tooltip="View messages"
                                v-on="{ ...menu }"
                            >
                              <v-icon size="13" color="white">{{
                                  typeToIcon(alerts(line, configSlot.items).type)
                                }}
                              </v-icon>
                              <v-icon size="13">mdi-chevron-down</v-icon>
                            </v-btn>
                          </template>
                          <v-card style="width: 150px" class="pa-3">
                            <div :class="alert.type+'--text'" style="font-size: 12px;"
                                 v-for="(alert,alertKey) in alerts(line,configSlot.items).alerts" :key="alert.message">
                              <v-icon size="12" class="mr-2" :color="alert.type">{{ typeToIcon(alert.type) }}</v-icon>
                              {{ alert.message }}
                            </div>
                          </v-card>
                        </v-menu>

                      </td>
                      <td v-for="(header,headerIndex) in headers" :key="line[header]+'-'+lineIndex+'-'+headerIndex">
                        {{ line[header] }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>


                <v-alert color="error" class="white--text font-weight-bold"
                         v-if="alertData.filter(_ => _.type === 'error').length > 0">
                  There are errors in the file. Please fix them before importing
                </v-alert>
                <v-alert color="warning" class="white--text font-weight-bold"
                         v-else-if="alertData.filter(_ => _.type === 'warning').length > 0">
                  There are warnings in the file. Please review them before importing
                </v-alert>

                <all-error-inline :errors="importErrors"/>

                <v-btn v-if="alertData.filter(_ => _.type === 'error').length === 0" color="success"
                       @click="importHoseConfigurations" :loading="importLoading"
                       :disabled="loading || readLines.length === 0">Process import for {{ readLines.length }}
                  configurations
                </v-btn>

              </template>
            </item-list-loader>

          </div>
        </template>
      </item-loader>
    </v-card>

  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import HoseConfigurationList from "../../../components/hose-configurations/HoseConfigurationList";
import ApiSelectBox from "@/components/ApiSelectBox.vue";
import ItemListLoader from "@/components/ItemListLoader.vue";
import ItemLoader from "@/components/ItemLoader.vue";
import * as _ from "lodash";
import {endpoints, httpRequest} from "@/store/network/hitag.client";
import GlobalErrorInline from "@/components/GlobalErrorInline.vue";
import HoseConfigurationView from "@/components/hose-configurations/HoseConfigurationView.vue";
import Papa from "papaparse";
import AllErrorInline from "@/components/AllErrorInline.vue";

export default {
  name: 'HoseConfigurationsImport',
  data() {
    return {
      selectedOrg: null,
      searchResetId: 0,
      loading: false,
      configs: [],
      errors: {},
      file: null,
      exportErrors: {},
      exportLoading: false,
      importErrors: {},
      importLoading: false,
      readLines: [],
      alertData: [],
      headers: [
        "Name",
        "Hose Type",
        "Bore size",
        "Display pressure",
        "Length",
        "Connection 1",
        "Connection 1 size",
        "Connection 1 type",
        "Connection 2",
        "Connection 2 size",
        "Connection 2 type",
        "Orientation",
        "Manufacturer",
        "Sleeving",
        "Test certificate",
        "Conformity certificate",
        "Notes",
      ]
    }
  },
  components: {
    AllErrorInline,
    HoseConfigurationView,
    GlobalErrorInline,
    ItemLoader,
    ItemListLoader,
    ApiSelectBox,
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    if (!this.clientAdmin) {
      this.selectedOrg = this.currentOrganisationId;
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'currentOrganisationId']),
    ...mapGetters('config', ['sizeMappings', 'hoseTypes', 'boreSizes', 'manufacturers', 'connectionTypes', 'connections', 'connectionsJumpSizes']),
  },
  methods: {
    async importHoseConfigurations() {
      this.importLoading = true;
      this.importErrors = {};
      try {
        let res = await httpRequest(endpoints.hoseConfigurations.importConfigurations(this.selectedOrg), {
          configurations: this.readLines.map(line => {
            return {
              id: line['DO-NOT-EDIT'] !== '' ? line['DO-NOT-EDIT'] : null,
              boreSize: line['Bore size'],
              connection1: line['Connection 1'],
              connection1JumpSize: line['Connection 1 size'],
              connection1Type: line['Connection 1 type'],
              connection2: line['Connection 2'],
              connection2JumpSize: line['Connection 2 size'],
              connection2Type: line['Connection 2 type'],
              displayPressure: line['Display pressure'] === '' ? 0 : line['Display pressure'],
              type: line['Hose Type'],
              length: parseInt(line['Length']),
              manufacturer: line['Manufacturer'],
              name: line['Name'],
              notes: line['Notes'],
              orientation: line['Orientation'] === '' ? 0 : line['Orientation'],
              sleeving: line['Sleeving'] === '1',
              testCertificate: line['Test certificate'] === '1',
              conformityCertificate: line['Conformity certificate'] === '1',
            }
          })
        });
        await this.$router.push({name: 'HoseConfigurations'});
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.importErrors = e.response.data;
      } finally {
        this.importLoading = false;
      }
    },
    typeToIcon(type) {
      switch (type) {
        case 'error':
          return 'mdi-alert';
        case 'warning':
          return 'mdi-alert-circle';
        case 'success':
          return 'mdi-check-circle';
      }
    },
    alerts(line, existingConfigs) {
      let alerts = [];
      //Check for duplicate line ids except empty ones
      var currentId = line['DO-NOT-EDIT'];

      if (currentId !== '') {
        if (this.readLines.filter(l => l['DO-NOT-EDIT'] === currentId).length > 1) {
          alerts.push({type: 'error', message: 'Duplicate line'});
        }
      }
      if (existingConfigs.some(config => config.name === line.Name)
          &&
          existingConfigs.filter(config => config.id.toLowerCase() === currentId.toLowerCase()).length === 0
      ) {
        alerts.push({type: 'error', message: 'Name already exists'});
      }
      //Check hose type exists in config
      if (!this.hoseTypes.some(hoseType => hoseType.name === line['Hose Type']) && !existingConfigs.some(config => config.type === line['Hose Type'])) {
        alerts.push({type: 'warning', message: 'Hose type is new'});
      }
      //Check bore size exists in config
      if (!this.boreSizes.some(boreSize => boreSize.name === line['Bore size']) && !existingConfigs.some(config => config.boreSize === line['Bore size'])) {
        alerts.push({type: 'warning', message: 'Bore size is new'});
      }
      //Check connection 1 exists in config
      if (!this.connections.some(connection => connection.name === line['Connection 1']) && !existingConfigs.some(config => config.connection1 === line['Connection 1'])) {
        alerts.push({type: 'warning', message: 'Connection 1 is new'});
      }
      //Check connection 1 type exists in config
      if (!this.connectionTypes.some(connectionType => connectionType.name === line['Connection 1 type']) && !existingConfigs.some(config => config.connection1Type === line['Connection 1 type'])) {
        alerts.push({type: 'warning', message: 'Connection 1 type is new'});
      }
      //Check connection 2 exists in config
      if (!this.connections.some(connection => connection.name === line['Connection 2']) && !existingConfigs.some(config => config.connection2 === line['Connection 2'])) {
        alerts.push({type: 'warning', message: 'Connection 2 is new'});
      }
      //Check connection 2 type exists in config
      if (!this.connectionTypes.some(connectionType => connectionType.name === line['Connection 2 type']) && !existingConfigs.some(config => config.connection2Type === line['Connection 2 type'])) {
        alerts.push({type: 'warning', message: 'Connection 2 type is new'});
      }
      if (isNaN(line['Length']) || line['Length'] === '') {
        alerts.push({type: 'error', message: 'Length is not a number'});
      }
      if (isNaN(line['Display pressure'])  || line['Display pressure'] === '') {
        alerts.push({type: 'error', message: 'Display pressure is not a number'});
      }
      //Check orientation is an integer
      if (isNaN(line['Orientation'])) {
        alerts.push({type: 'error', message: 'Orientation is not a number'});
      }
      if(line['Sleeving'] !== '1' && line['Sleeving'] !== '0' && line['Sleeving'] !== '') {
        alerts.push({type: 'error', message: 'Sleeving must be 1,0  or blank'});
      }
      if(line['Test certificate'] !== '1' && line['Test certificate'] !== '0' && line['Test certificate'] !== '') {
        alerts.push({type: 'error', message: 'Test certificate must be 1,0  or blank'});
      }
      if(line['Conformity certificate'] !== '1' && line['Conformity certificate'] !== '0' && line['Conformity certificate'] !== '') {
        alerts.push({type: 'error', message: 'Conformity certificate must be 1,0  or blank'});
      }
      //Check manufacturer exists in config
      if (!this.manufacturers.some(manufacturer => manufacturer.name === line['Manufacturer']) && !existingConfigs.some(config => config.manufacturer === line['Manufacturer'])) {
        alerts.push({type: 'warning', message: 'Manufacturer is new'});
      }

      if (alerts.filter(alert => alert.type === 'error').length > 0) {
        alerts.push({type: 'error', message: 'This line will prevent file being imported'});
      } else {
        if (currentId !== '') {
          if (existingConfigs.some(config => config.id.toLowerCase() === currentId.toLowerCase())) {
            alerts.push({type: 'success', message: 'Updating config'});
          } else {
            alerts.push({type: 'error', message: 'Please leave DO-NOT-EDIT empty for new configs'});
          }
        } else {
          alerts.push({type: 'success', message: 'Creating new config'});
        }
      }

      return {
        type: alerts.filter(alert => alert.type === 'error').length > 0 ? 'error' : alerts.filter(alert => alert.type === 'warning').length > 0 ? 'warning' : 'success',
        alerts: alerts
      };
    },
    updateConfigs(configs) {
      this.configs = configs;
      this.updateAlerts();
    },
    updateAlerts() {
      let vm = this;
      this.alertData = this.readLines.map(line => vm.alerts(line, vm.configs));
    },
    readFile() {
      this.errors = {};
      this.loading = true;
      this.readLines = [];

      if (this.file) {
        Papa.parse(this.file, {
          headers: true,
          encoding: "ISO-8859-1",
          skipEmptyLines: true,
          complete: (results) => {
            console.log("results", results);
            let headers = results.data[0];
            //Get the rest of the data in a variable skipping the header
            // check if header values match
            let headerErrors = [];
            headers.forEach((header, index) => {
              // Trim quotes if necessary
              header = header.replace(/^"|"$/g, '');
              if (index === 0) {
                if (header !== 'DO-NOT-EDIT') {
                  headerErrors.push(`Expected DO-NOT-EDIT but got ${header}`);
                }
              } else {
                if (header !== this.headers[index - 1]) {
                  headerErrors.push(`Expected ${this.headers[index - 1]} but got ${header}`);
                }
              }
            });
            if (headerErrors.length > 0) {
              this.errors = {global: headerErrors};
              this.loading = false;
              return;
            }
            if (headers.length - 1 !== this.headers.length) {
              this.errors = {global: ["CSV does not match template"]};
              this.loading = false;
              return;
            }
            let lines = [];
            for (let i = 1; i < results.data.length; i++) {
              // Properly split each line considering commas inside quotes
              let data = results.data[i];
              if (data.length === headers.length) {
                let tarr = {};
                for (let j = 0; j < headers.length; j++) {
                  tarr[headers[j]] = data[j];
                }
                lines.push(tarr);
              }
            }
            this.readLines = lines;
            this.updateAlerts();
            this.loading = false;
          },
          error: (error) => {
            this.errors = {global: [error.message]};
            this.loading = false;
          }
        });
      }
    },
    async exportExisting(blank = false) {
      this.exportErrors = {}
      this.exportLoading = true;
      try {
        let res = await httpRequest(endpoints.hoseConfigurations.exportTemplate(this.selectedOrg,blank), {});
        window.open(res.data.url, '_blank');
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.exportErrors = e.response.data;
      } finally {
        this.exportLoading = false;
      }
    },
  }
}
</script>
